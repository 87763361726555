<template>
  <div class="jh-container is-chat-main jh-cols">
    <!--채팅목록-->
    <div class="jh-card is-col-fix" style="width: 400px;">
      <div class="jh-card-header">
        <div class="is-left">
          <v-btn class="jh-btn is-icon" @click="getchatList"><i class="jh-icon-refresh"></i></v-btn>
          <v-btn class="jh-btn is-icon" @click="chatListSortFilter = !chatListSortFilter"><i class="jh-icon-filter"></i></v-btn>
        </div>
        <div class="is-right">
          <v-select
            class="jh-form is-round"
            style="width:150px"
            :items="USER_STAT"
            v-model="USER_NEWSTAT"
            placeholder="선택하세요"
            v-on:change="changeUserStat"
          >            
            <template slot="selection" slot-scope="data">
              <label :class="'jh-label-circle '+ data.item.label"></label>
              <div> {{ data.item.text }} </div>
            </template>
            <template slot="item" slot-scope="data">
                <div :class="'jh-label-circle '+ data.item.label"></div>
                <div> {{ data.item.text }} </div>
            </template>
          </v-select>
        </div>
      </div>
      <div class="jh-card-body is-p-0">
        <v-expand-transition>
          <div class="is-chat-filter" v-show="chatListSortFilter">
            <v-btn-toggle v-model="sortBy" mandatory class="jh-btn-group">
                <v-btn class="jh-btn has-icon" v-on:click="sortClick"><sapn>상담시간순</sapn><v-icon x-small>{{sortIcon}}</v-icon></v-btn>
                <v-btn class="jh-btn has-icon" v-on:click="sortClick"><sapn>최근대화순</sapn><v-icon x-small>{{sortIcon}}</v-icon></v-btn>
            </v-btn-toggle>
          </div>
        </v-expand-transition>
        <div class="jh-chat-list">
          <div class="jh-no-data" :class="[noData ? '' : 'is-hidden']">현재 데이터가 없습니다.</div>
          <ul>
            <li :class="{'is-active' : activeIndex === index }" v-for="(item, index) in chatList" @click.stop="clickChat(index)">
              <div class="is-left">
                <i class="jh-icon-channel"></i>
              </div>
              <div class="is-center">
                <span class="is-key">{{item.key}}</span>
                <span class="is-ready-time">00:00:00</span>
                <div class="is-text">{{item.text}}</div>
              </div>
              <div class="is-right">
                <label class="is-chat-state" :class="item.stateClass">{{item.state}}</label>
                <span class="is-time">00:00</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="jh-card-footer is-chat-btn">
        <v-btn type="button" class="jh-chat-list-btn"
            v-for="item in CahtListBtn"
            :key="item.id"
            :class="{'is-active': item.isActive}"
            @click="item.isActive = !item.isActive">
            <span>{{ item.text }}</span>
            <span class="is-value">{{ item.value }}</span>
        </v-btn>
      </div>
    </div>
    <!--//채팅목록-->

    <!--채팅상담-->
    <div class="jh-card is-cnsl">
      <div class="jh-card-header">
        <span class="jh-badge is-blueGray">문의유형</span>
        <v-tooltip content-class="jh-tooltip" bottonm nudge-bottom="150" nudge-right="0" max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <p class="is-text" v-bind="attrs" v-on="on">문의유형1 > 문의유형2 > 문의유형3</p>
          </template>
          <span>문의유형1 > 문의유형2 > 문의유형3</span>
        </v-tooltip>
        
        <div class="is-right"><v-btn class="jh-btn is-icon" @click="getchatList"><i class="jh-icon-refresh"></i></v-btn></div>
      </div>
      <div class="jh-card-body is-p-0">
        <table class="jh-tbl-detail is-chat-info">
          <colgroup>
            <col width="80px">
            <col>
            <col width="80px">
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th><label>인입경로</label></th>
              <td colspan="3"><span class="is-value">WEB-HTS</span></td>
            </tr>
            <tr>
              <th><label>로그인정보</label></th>
              <td><span class="is-value">abscd</span></td>
              <th><label>휴대전화</label></th>
              <td><span class="is-value">010-0000-0000</span></td>
            </tr>
          </tbody>
        </table>
        <div class="jh-mssg-list-area" id="scroll-target" v-scroll:#scroll-target="onScroll">
          <div class="is-mssg-divider">2022년 8월 3일</div>
          <div class="jh-mssg-list">
            <div class="from-right">
              <div class="is-name">챗봇</div>
              <div class="is-mssg">상담을 시작하려면 서비스를 선택해 주세요
                <!-- <div class="is-view-all">전체보기<i class="jh-icon-angle-right-xs"></i></div> -->
              </div>
              <div>
                <!-- <v-menu offset-y open-on-hover content-class="jh-mssg-menu" >
                  <template v-slot:activator="{ on }">
                      <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button>
                  </template>
                  <div class="jh-mssg-menu-layer">
                      <p><i class="jh-icon-trash is-white"></i><span>삭제</span></p>
                  </div>
                </v-menu> -->
                <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button>
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
            <div class="from-left">
              <div class="is-mssg">청약안내 문의드립니다.
                <div class="is-view-all" @click="dialogTotalMsg=true">전체보기<i class="jh-icon-angle-right-xs"></i></div>
              </div>
              <div>
                <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button>
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
            <div class="from-right">
              <div class="is-name">ebest</div>
              <div class="is-mssg">상담사가 상담을 준비 중입니다. 잠시만 기다려 주세요.
                <!-- <div class="is-view-all">전체보기<i class="jh-icon-angle-right-xs"></i></div> -->
              </div>
              <div>
                <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button>
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
            <div class="from-right">
              <div class="is-name">박지원</div>
              <div class="is-mssg">안녕하세요<br>언제나 내편이 되는 투자!<br>이베스트투자증권입니다.
                <!-- <div class="is-view-all">전체보기<i class="jh-icon-angle-right-xs"></i></div> -->
              </div>
              <div>
                <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button>
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
            <div class="from-right">
              <div class="is-name">박지원</div>
              <div class="is-mssg"><img src="/emoticon/white1.gif">
                <div class="is-view-all" @click="dialogTotalImage=true">전체보기<i class="jh-icon-angle-right-xs"></i></div>
              </div>
              <div>
                <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button>
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
            <div class="from-right">
              <div class="is-name">박지원</div>
              <div class="is-mssg"><div class="jh-info-txt is-fill">삭제된 내용입니다.</div>
                <!-- <div class="is-view-all">전체보기<i class="jh-icon-angle-right-xs"></i></div> -->
              </div>
              <div>
                <!-- <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button> -->
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
            <div class="from-right">
              <div class="is-name">박지원</div>
              <div class="is-mssg"><img src="/emoticon/trans1.gif">
                <!-- <div class="is-view-all">전체보기<i class="jh-icon-angle-right-xs"></i></div> -->
              </div>
              <div>
                <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button>
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
            <div class="from-left">
              <div class="is-mssg">청약하고 싶어요.
                <!-- <div class="is-view-all">전체보기<i class="jh-icon-angle-right-xs"></i></div> -->
              </div>
              <div>
                <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button>
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
            <div class="from-left">
              <div class="is-mssg"><div class="jh-info-txt is-fill">삭제된 내용입니다.</div>
                <!-- <div class="is-view-all">전체보기<i class="jh-icon-angle-right-xs"></i></div> -->
              </div>
              <div>
                <!-- <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button> -->
                <span class="is-mssg-time">09:24</span>
              </div>
            </div>
          </div>
          <div class="is-mssg-divider">상담이 종료되었습니다. 상담이력을 저장해 주시기 바랍니다.</div>
          
          <div id="scrollMe" ref="scrollToMe"></div>          
          <v-btn class="jh-btn is-to-bottom" v-if="ToBottomButton" @click="toBottomClick"><i class="jh-icon-arrow-down-lg"></i></v-btn>
        </div>
        <div class="jh-mssg-control" v-show="!CnslSave">
          <v-btn class="jh-btn" @click="clickTemp">임시저장</v-btn>
          <v-btn class="jh-btn is-fill" @click="clickDone">상담종료</v-btn>
        </div>
        <div class="jh-mssg-frame" v-show="!CnslSave">
          <div class="jh-mssg-form" style="max-height: 230px;">
            <v-textarea class="jh-form is-trns" noResize auto-grow rows="3" row-height="25" placeholder="상담내용을 입력하세요."></v-textarea>
          </div>
          <div class="jh-mssg-form-footer">
            <div class="is-left">
              <v-btn class="jh-btn is-icon" title="이미지선택" @click="showDialog('P101101')"><i class="jh-icon-chat-image"></i></v-btn>
              <v-btn class="jh-btn is-icon" :title="[mssgStop ? '메시지재개' : '메시지중단']" @click="mssgStop = !mssgStop"><i :class="[mssgStop ? 'jh-icon-chat-play' : 'jh-icon-chat-stop']"></i></v-btn>
              <v-btn class="jh-btn is-icon" title="종료메시지전송"><i class="jh-icon-chat-complete"></i></v-btn>
              <v-btn class="jh-btn is-icon" title="상담전달" @click="clickTransfer"><i class="jh-icon-chat-transfer"></i></v-btn>
            </div>
            <div class="is-right"><v-btn class="jh-btn is-main" @click="toBottomClick">전송</v-btn></div>
          </div>
        </div>

        <!--임시저장-->
        <div class="is-detail-form" :class="[ChatTemp ? 'is-active' : '']">
          <div class="jh-ui-header">
            <h2>임시저장</h2>
          </div>
          <table class="jh-tbl-detail">
            <colgroup>
              <col width="80px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                  <th><label class="is-required">상담결과</label></th>
                  <td><v-select class="jh-form" placeholder="상담결과" :items="dropItemsResult" item-text="title"></v-select></td>
              </tr>
              <tr>
                  <th><label class="is-required">상담유형</label></th>
                  <td> 
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형1"
                        :items="CNSL_TY1" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY1" v-on:change="chnCnsl1()"></v-select>
                        <v-select class="jh-form" placeholder="상담유형2"
                        :items="CNSL_TY2" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY2" v-on:change="chnCnsl2()"></v-select>
                      </div>
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형3"
                        :items="CNSL_TY3" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY3" v-on:change="chnCnsl3()"></v-select>
                        <!-- <v-select class="jh-form" placeholder="상담유형4"
                        :items="CNSL_TY4" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY4" v-on:change="chnCnsl4()"></v-select> -->
                      </div>
                  </td>
              </tr>
              <tr>
                  <th><label class="is-required">상담제목</label></th>
                  <td><v-text-field class="jh-form" placeholder="상담제목" name="name"  id="id" v-model="CHT_TIT"></v-text-field></td>
              </tr>
              <tr>
                  <th><label class="is-required">상담내용</label></th>
                  <td><v-textarea class="jh-form" noResize rows="5" placeholder="상담내용을 입력하세요."></v-textarea></td>
              </tr>
            </tbody>
          </table>
          <div class="jh-btn-area">
            <v-btn class="jh-btn" @click="clickTempCancel">취소</v-btn>
            <v-btn class="jh-btn is-main" @click="clickTempSave">저장</v-btn>
          </div>
        </div>
        <!--//임시저장-->

        <!--상담종료-->
        <div class="is-detail-form" :class="[ChatDone ? 'is-active' : '']">
          <div class="jh-ui-header">
            <h2>상담종료</h2>
          </div>
          <table class="jh-tbl-detail">
            <colgroup>
              <col width="80px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                  <th><label class="is-required">상담결과</label></th>
                  <td><v-select class="jh-form" placeholder="상담결과" :items="dropItemsResult" item-text="title"></v-select></td>
              </tr>
              <tr>
                  <th><label class="is-required">상담유형</label></th>
                  <td> 
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형1"
                        :items="CNSL_TY1" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY1" v-on:change="chnCnsl1()"></v-select>
                        <v-select class="jh-form" placeholder="상담유형2"
                        :items="CNSL_TY2" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY2" v-on:change="chnCnsl2()"></v-select>
                      </div>
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형3"
                        :items="CNSL_TY3" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY3" v-on:change="chnCnsl3()"></v-select>
                        <!-- <v-select class="jh-form" placeholder="상담유형4"
                        :items="CNSL_TY4" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY4" v-on:change="chnCnsl4()"></v-select> -->
                      </div>
                  </td>
              </tr>
              <tr>
                  <th><label class="is-required">상담제목</label></th>
                  <td><v-text-field class="jh-form" placeholder="상담제목" name="name"  id="id" v-model="CHT_TIT"></v-text-field></td>
              </tr>
              <tr>
                  <th><label class="is-required">상담내용</label></th>
                  <td><v-textarea class="jh-form" noResize rows="5" placeholder="상담내용을 입력하세요."></v-textarea></td>
              </tr>
            </tbody>
          </table>
          <div class="jh-btn-area">
            <v-btn class="jh-btn" @click="clickDoneCancel">취소</v-btn>
            <v-btn class="jh-btn is-main" @click="clickDoneSave">저장</v-btn>
          </div>
        </div>
        <!--//상담종료-->

        <!--상담전달-->
        <div class="is-detail-form" :class="[ChatTransfer ? 'is-active' : '']">
          <div class="jh-ui-header">
            <h2>상담전달</h2>
          </div>
          <table class="jh-tbl-detail">
            <colgroup>
              <col width="80px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                  <th><label class="is-required">상담사선택</label></th>
                  <td>
                    <v-autocomplete
                      class="jh-form"
                      v-model="select_member"
                      :items="member_items"
                      no-data-text="일치하는 데이터가 없습니다."
                      item-text="name"
                      item-value="name"
                      placeholder="상담사선택"
                    >
                      <template v-slot:selection="data">
                        <span class="auto-value">{{ data.item.id }}</span>
                        <span class="auto-value">{{ data.item.name }}</span>
                        <span class="auto-group">{{ data.item.group }}</span>
                      </template>
                      <template v-slot:item="data">
                        <template>
                            <span class="auto-value" v-html="data.item.id"></span>
                            <span class="auto-value" v-html="data.item.name"></span>
                            <span class="auto-group" v-html="data.item.group"></span>
                        </template>
                      </template>
                    </v-autocomplete>
                  </td>
              </tr>
              <tr>
                  <th><label class="is-required">상담유형</label></th>
                  <td> 
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형1"
                        :items="CNSL_TY1" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY1" v-on:change="chnCnsl1()"></v-select>
                        <v-select class="jh-form" placeholder="상담유형2"
                        :items="CNSL_TY2" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY2" v-on:change="chnCnsl2()"></v-select>
                      </div>
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형3"
                        :items="CNSL_TY3" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY3" v-on:change="chnCnsl3()"></v-select>
                        <!-- <v-select class="jh-form" placeholder="상담유형4"
                        :items="CNSL_TY4" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY4" v-on:change="chnCnsl4()"></v-select> -->
                      </div>
                  </td>
              </tr>
              <tr class="is-hidden">
                  <th><label class="is-required">상담제목</label></th>
                  <td><v-text-field class="jh-form" placeholder="상담제목" name="name"  id="id" v-model="CHT_TIT"></v-text-field></td>
              </tr>
              <tr>
                  <th><label class="is-required">상담내용</label></th>
                  <td><v-textarea class="jh-form" noResize rows="5" placeholder="상담내용을 입력하세요."></v-textarea></td>
              </tr>
            </tbody>
          </table>
          <div class="jh-btn-area">
            <v-btn class="jh-btn" @click="clickTransferCancel">취소</v-btn>
            <v-btn class="jh-btn is-main" @click="clickTransferSave">전달</v-btn>
          </div>
        </div>
        <!--//상담전달-->
      </div>
    </div>
    <!--//채팅상담-->

    <div class="is-vrt-2">
      <!--고객정보-->
      <div class="jh-card is-mb-10" style="height: 277px;min-height: 277px;" v-if="custInfo">
        <div class="jh-card-header has-tab">
          <v-tabs v-model="tab1" slider-size="4">
            <v-tab key="tab1_1">고객정보</v-tab>
          </v-tabs>
          <p class="jh-tab-button is-right"><v-btn class="jh-btn is-main">저장</v-btn></p>
        </div>
        <div class="jh-card-body is-p-5">
          <table class="jh-tbl-detail">
            <colgroup>
              <col width="65px">
              <col width="170px">
              <col width="65px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                  <th><label>고객성향</label></th>
                  <td class="is-txt-center"><span class="is-value">이해력</span></td>
                  <th><label>문제행동</label></th>
                  <td class="is-txt-center is-bg-yellow"><span class="is-value">Yellow</span></td>
              </tr>
            </tbody>
          </table>
          <table class="jh-tbl-detail is-mt-5">
            <colgroup>
              <col width="65px">
              <col width="170px">
              <col width="65px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                  <th><label>계좌번호</label></th>
                  <td>                    
                    <div class="jh-align-middle jh-align-space">
                      <span class="is-value">2010000000-01</span>
                      <v-btn class="jh-btn has-icon-only"><i class="jh-icon-keypad"></i></v-btn>
                    </div>            
                  </td>
                  <th><label>고객명</label></th>
                  <td><span class="is-value" style="font-size: 14px;">김이베스트</span></td>
              </tr>
              <tr>
                  <th rowspan="2"><label>주민번호</label></th>
                  <td class="is-bg-light-red">                    
                    <div class="jh-align-middle jh-align-space">
                      <span class="is-value">070203-4******</span>
                      <v-btn class="jh-btn has-icon-only"><i class="jh-icon-keypad"></i></v-btn>
                    </div>                    
                  </td>
                  <th><label>계좌비번</label></th>
                  <td class="is-bg-light-yellow">                    
                    <div class="jh-align-middle jh-align-space">
                      <span class="is-value">070203-4******</span>
                      <v-btn class="jh-btn has-icon-only"><i class="jh-icon-password"></i></v-btn>
                    </div>                    
                  </td>
              </tr>
              <tr>
                  <td>
                    <div class="jh-cols is-space-5">
                        <span class="is-value is-bold">만 15세</span>
                        <span class="is-value is-bold is-txt-red">미성년자</span>
                        <span class="is-value is-bold is-txt-green">외국인</span>
                    </div>
                  </td>
                  <th><label>전화번호</label></th>
                  <td><span class="is-value"></span></td>
              </tr>
              <tr>
                  <th><label>고객번호</label></th>
                  <td><span class="is-value"></span></td>
                  <th><label>고객등급</label></th>
                  <td><span class="is-value"></span></td>
              </tr>
              <tr>
                  <th><label>고객메모</label></th>
                  <td colspan="3"><v-textarea class="jh-form" noResize rows="3" placeholder="메모내용을 입력하세요."></v-textarea></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--//고객정보-->

      <!--스크립트/링크-->
      <div class="jh-card is-auto">
        <div class="jh-card-header has-tab">
          <v-tabs v-model="tab2" slider-size="4">
            <v-tab key="tab2_1">스크립트</v-tab>
            <v-tab key="tab2_2">링크</v-tab>
          </v-tabs>
          <p class="jh-tab-button is-right">
            <v-btn class="jh-btn is-icon" :title="[custInfo ? '확대' : '축소']" @click="custInfo=!custInfo"><i :class="[custInfo ? 'jh-icon-transfer-up' : 'jh-icon-transfer-down']"></i></v-btn>
          </p>
        </div>
        <div class="jh-card-body is-p-5">
          <v-tabs-items v-model="tab2">
            <!--스크립트-->
            <v-tab-item key="tab2_1">
              <div class="tab-contnets is-script">
                <div class="jh-search-group">
                  <v-select class="jh-form  is-md is-trns" style="max-width: 110px;" :items="items"></v-select>
                  <v-text-field class="jh-form is-md is-trns" placeholder="검색어를 입력하세요"></v-text-field>
                  <v-btn class="jh-btn is-icon"><i class="jh-icon-search is-blue"></i></v-btn>
                </div>                
                <div class="jh-script-detail" :class="[scriptDetail ? 'is-active' : '']">
                  <div class="jh-script-detail-toolbar">
                      <div class="is-left">
                        <button type="button" class="jh-btn is-icon" title="단축키설정" @click="ShortKey=!ShortKey"><i class="jh-icon-chat-key"></i></button>
                        <button type="button" class="jh-btn is-icon" title="이어붙이기"><i class="jh-icon-chat-push"></i></button>
                        <button type="button" class="jh-btn is-icon" title="새로붙이기"><i class="jh-icon-chat-push-new"></i></button>
                        <!-- <button type="button" class="jh-btn is-icon" title="수정" @click="scriptFormClick"><i class="jh-icon-edit"></i></button>
                        <button type="button" class="jh-btn is-icon" title="삭제"><i class="jh-icon-trash"></i></button> -->
                      </div>  
                      <div class="is-right">
                        <button type="button" class="jh-btn is-trns is-right" @click="[scriptDetail=!scriptDetail, ShortKey=false]"><i class="jh-icon-close-xs"></i>닫기</button>
                      </div>
                  </div>
                  <div class="jh-script-detail-body">
                    <div class="is-shortkey" v-if="ShortKey">
                      <div class="jh-ui-header">
                        <h3>단축키설정</h3>
                        <div class="is-right">
                          <v-btn class="jh-btn" @click="ShortKey=false">취소</v-btn>
                          <v-btn class="jh-btn is-fill" @click="ShortKey=false">저장</v-btn>
                        </div>
                      </div>
                      <table class="jh-tbl-detail">
                        <colgroup>
                          <col width="80px">
                          <col>
                        </colgroup>
                        <tbody>
                          <tr>
                            <th><label>이어붙이기</label></th>
                            <td><v-text-field class="jh-form" clearable placeholder="단축키를 입력하세요"></v-text-field></td>
                          </tr>
                          <tr>
                            <th><label>새로붙이기</label></th>
                            <td><v-text-field class="jh-form" clearable placeholder="단축키를 입력하세요"></v-text-field></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h1>{{subject}} <label>Ctrl</label><span>+</span><label>1</label> <label>Ctrl</label><span>+</span><label>Enter</label></h1>
                    <p>{{content}}</p>
                  </div>
                </div>
                <v-data-table
                  class="jh-grid"
                  v-model="ScriptList"
                  :headers="ScriptHeaders"
                  :items="rowNumScript"
                  item-key="index"
                  :single-select="singleSelect"
                  @click:row="rowClick"
                  fixed-header
                  hide-default-footer
                  :page.sync="page"
                  @page-count="pageCount = $event"
                >
                  <template v-slot:item.subject="{ item }"><div class="is-ellips" :title="item.subject">{{ item.subject }}</div></template>
                </v-data-table>
                <div class="jh-pager">
                  <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
                  <v-select
                    class="jh-form is-round is-right"
                    :items="perPage"                  
                    label="10"
                    style="width: 70px"
                  ></v-select>
                  <span class="jh-counter is-left">총 <em>{{ rowNumScript.length }}</em> 건</span>
                </div>
              </div>
            </v-tab-item>
            <!--//스크립트-->

            <!--링크-->
            <v-tab-item key="tab2_2">
              <div class="tab-contnets">  
                <div class="jh-search-group is-mb-5">
                  <v-text-field class="jh-form is-md is-trns" placeholder="검색어를 입력하세요"></v-text-field>
                  <v-btn class="jh-btn is-icon"><i class="jh-icon-search is-blue"></i></v-btn>
                </div>               
                <v-data-table
                  class="jh-grid"
                  v-model="LinkList"
                  :headers="LinkHeaders"
                  :items="rowNumLink"
                  item-key="index"
                  fixed-header
                  hide-default-footer
                  :page.sync="page"
                  @page-count="pageCount = $event"
                >
                  <template v-slot:item.send>
                    <button type="button" class="jh-btn is-icon"><i class="jh-icon-chat-push"></i></button>
                  </template>
                </v-data-table>
                <div class="jh-pager">
                  <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
                  <v-select
                    class="jh-form is-round is-right"
                    :items="perPage"                  
                    label="10"
                    style="width: 70px"
                  ></v-select>
                  <span class="jh-counter is-left">총 <em>{{ rowNumLink.length }}</em> 건</span>
                </div>
              </div>
            </v-tab-item>
            <!--//링크-->
        </v-tabs-items>
        </div>
      </div>
      <!--//스크립트/링크-->
    </div>

    <!--상담이력-->
    <div class="jh-card is-col-fix" style="width: 400px;">
      <div class="jh-card-header has-tab">
        <v-tabs v-model="tab3" slider-size="4">
          <v-tab key="tab3_1">상담이력</v-tab>
        </v-tabs>
        <p class="jh-tab-button is-right">
          <v-btn class="jh-btn is-icon"><i class="jh-icon-refresh"></i></v-btn>
        </p>
      </div>
      <div class="jh-card-body is-p-5">
        <v-tabs-items v-model="tab3">
          <v-tab-item key="tab3_1">
            <div class="tab-contnets"> 
              <v-data-table
                class="jh-grid"
                v-model="HistoryList"
                :headers="HistoryHeaders"
                :items="rowNumHistory"
                item-key="index"
                fixed-header
                hide-default-footer
                singleSelect
                singleExpand
                :expanded.sync="expanded"
                @click:row="rowHistoryClick"
                :page.sync="page"
                @page-count="pageCount = $event"
              >
              <template v-slot:item.view>
                <button type="button" class="jh-btn is-icon"><i class="jh-icon-comment"></i></button>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="is-p-0">
                  <ul class="jh-list-detail">
                    <li><label>상담유형</label><p>상담유형1>상담유형2>상담유형3>상담유형4</p></li>
                    <li class="jh-cols">
                      <div>
                          <label>상담사ID</label><p>teletalk</p>
                      </div>
                      <div>
                          <label>상담사명</label><p>{{ item.name }}</p>
                      </div>
                    </li>
                    <li class="jh-cols">
                      <div>
                          <label>처리상태</label><p>상담완료</p>
                      </div>
                      <div>
                          <label>접수일시 </label><p>2020/04/30 10:40:10</p>
                      </div>
                    </li>
                    <li class="jh-cols">
                      <div>
                          <label>대기시간 </label><p>10분</p>
                      </div>
                      <div>
                          <label>총소요시간</label><p>23분</p>
                      </div>
                    </li>
                    <li class="jh-cols">
                      <div>
                          <label>상담시작일시</label><p>2020/04/30 10:30:23</p>
                      </div>
                      <div>
                          <label>상담종료일시</label><p>2020/04/30 10:40:10</p>
                      </div>
                    </li>
                    <li><label>상담내용</label><p>상담내용</p></li>
                  </ul>
                </td>
              </template>
              </v-data-table>
              <div class="jh-pager">
                <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
                <v-select
                  class="jh-form is-round is-right"
                  :items="perPage"                  
                  label="10"
                  style="width: 70px"
                ></v-select>
                <span class="jh-counter is-left">총 <em>{{ rowNumHistory.length }}</em> 건</span>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>        
      </div>
    </div>
    <!--//상담이력-->      

    <!-- 장문 전체보기 -->
    <v-dialog v-model="dialogTotalMsg" max-width="500" content-class="jh-dialog-wrap has-body-scroll">            
      <div class="jh-dialog-header">
        <h1>장문대화 전체보기</h1>
        <div class="is-right">
          <button type="button" class="jh-btn is-icon" title="닫기" @click="dialogTotalMsg=false"><i class="jh-icon-close-lg is-white"></i></button>
        </div>
      </div>
      <div class="jh-dialog-body"><div class="jh-mssg-all">{{msg_all}}</div></div>
    </v-dialog>

    <!-- 이미지 전체보기 -->
    <v-dialog v-model="dialogTotalImage" content-class="jh-dialog-wrap has-body-scroll has-img">                
      <div class="jh-dialog-header">
        <h1>이미지 보기</h1>
        <div class="is-right">
          <button type="button" class="jh-btn is-icon" title="닫기" @click="dialogTotalImage=false"><i class="jh-icon-close-lg is-white"></i></button>
        </div>
      </div>
      <div class="jh-dialog-body"><img src="/emoticon/trans1.gif"></div>                
    </v-dialog>

    <!-- 이미지 전송 -->
    <v-dialog width="1600" v-model="DialogP101101">
      <dialog-P101101
          headerTitle="이미지 전송"
          @hide="hideDialog('P101101')"
      ></dialog-P101101>
      <template v-slot:body></template>
    </v-dialog>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import DialogP101101 from "../page/P101101.vue";


export default {
  name: "MENU_M110102", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogP101101
  },
  data() {
    return {
      //상담사 상태
      USER_NEWSTAT: '채팅대기',
      USER_STAT: [
          { text: '채팅대기', label: 'is-bg-yellow' },
          { text: '채팅ON', label: 'is-bg-blue' },
          { text: '휴식', label: 'is-bg-pink' },
          { text: '점심', label: 'is-bg-org' },
          { text: '교육', label: 'is-bg-purple' },
          { text: '회의', label: 'is-bg-green' },
          { text: '다른작업', label: 'is-bg-blueGray' },
          { text: '추가작업', label: 'is-bg-red' }
      ],

      //채팅목록필터
      chatListSortFilter: false,
      sortBy: 0,
      sortIcon: 'mdi-arrow-up',

      //채팅목록
      chatList: [
        { key: 'key123', text: 'MTS종목토론실 연결 서비스 종료가 언젠가요? 설명해 주시면 감사하겠습니다.', state: '대기', stateClass: 'is-bg-yellow'},
        { key: 'key123', text: 'MTS종목토론실 연결 서비스 종료가 언젠가요? 설명해 주시면 감사하겠습니다.', state: '상담중', stateClass: 'is-bg-org'},
        { key: 'key123', text: 'MTS종목토론실 연결 서비스 종료가 언젠가요? 설명해 주시면 감사하겠습니다.', state: '호전환', stateClass: 'is-bg-purple'},
        { key: 'key123', text: 'MTS종목토론실 연결 서비스 종료가 언젠가요? 설명해 주시면 감사하겠습니다.', state: '종료', stateClass: 'is-bg-gray'},
      ],
      activeIndex: -1,

      //채팅목록 하단 버튼
      CahtListBtn: null,
      CahtListBtn: [
          { id: 'chat_list_01', text: '상담대기', value: '5', isActive: false },
          { id: 'chat_list_02', text: '상담중', value: '0', isActive: false },
          { id: 'chat_list_04', text: '후처리', value: '20', isActive: false },
          { id: 'chat_list_03', text: '종료', value: '120', isActive: false },
      ],

      //채팅상담저장관련
      CnslSave: false,
      ChatTemp: false,
      ChatDone: false,
      ChatTransfer: false,
      ToBottomButton: false,

      dialogTotalMsg: false,
      dialogTotalImage: false,
      DialogP101101: false,
      
      mssgStop: false,        
      member_items: [
          {id: 'teletalk01', name: '홍길동', group: '상담1팀'},
          {id: 'teletalk02', name: '유재순', group: '상담2팀'},
          {id: 'teletalk03', name: '김상담', group: '상담3팀'},
          {id: 'teletalk04', name: '유재석', group: '상담4팀'},
          {id: 'teletalk05', name: '강호동', group: '상담5팀'},
      ], 
      
      custInfo: true,

      tab2: 'tab2_1',
      //스크립트
      
      page: 1,
      pageCount: 0,
      perPage: ['10','50','100','200'],        
      pagination  : {page : 2 , rowsPerPage : 5, sortBy : '', descending : ''},
      
      ScriptHeaders: [
          { text: 'NO', value: 'index', align: 'center', width: '50px' },
          { text: '유형', value: 'type', align: 'center', width: '60px' },
          { text: '스크립트명', value: 'subject'},
          { text: '수정일', value: 'date', align: 'center', width: '80px' },
      ],    
      singleSelect: true,
      ScriptList: [],
      ScriptData: [
        {
            type: '공통',
            subject: '취소수수료를 환불해 주세요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
        },
        {
            type: '공통',
            subject: '호출중이었는데 앱이 꺼졌어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '택시에 물건을 두고 내렸어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '호출을 기다리는데 기사님이 취소했어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '카드 등록이 안되요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '공통',
            subject: '취소수수료를 환불해 주세요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
        },
        {
            type: '공통',
            subject: '호출중이었는데 앱이 꺼졌어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '택시에 물건을 두고 내렸어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '호출을 기다리는데 기사님이 취소했어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '카드 등록이 안되요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '공통',
            subject: '취소수수료를 환불해 주세요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
        },
        {
            type: '공통',
            subject: '호출중이었는데 앱이 꺼졌어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '택시에 물건을 두고 내렸어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '호출을 기다리는데 기사님이 취소했어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
        {
            type: '개인',
            subject: '카드 등록이 안되요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.'
        },
      ],
      scriptDetail: false,
      ShortKey: false,

      
      LinkHeaders: [
          { text: 'NO', value: 'index', align: 'center', width: '50px' },
          { text: '링크명', value: 'subject'},
          { text: '전송', value: 'send', align: 'center', width: '50px', sortable: false },
      ],  
      LinkData: [
        {subject: '네이버',},
      ],

      HistoryHeaders: [
          { text: 'NO', value: 'index', align: 'center', width: '30px', sortable: false },
          { text: '채팅', value: 'view', align: 'center', width: '40px', sortable: false },
          { text: '상담제목', value: 'subject'},
          { text: '상담사', value: 'name', align: 'center', width: '60px', sortable: false },
      ],  
      HistoryData: [
        {subject: '상담제목', name: '홍길동'},
        {subject: '상담제목', name: '홍길동'},
        {subject: '상담제목', name: '홍길동'},
        {subject: '상담제목', name: '홍길동'},
        {subject: '상담제목', name: '홍길동'},
      ],
    }
  },
  methods: {
    sortClick: function () { 
        let icon = this.sortIcon;
        if(icon == 'mdi-arrow-up'){
            this.sortIcon='mdi-arrow-down';
        }else{
            this.sortIcon='mdi-arrow-up';
        }
    },
    clickChat(index){
      this.activeIndex = index;
    },
    clickTemp: function (){
      this.ChatTemp = true;
      this.CnslSave = true;
      this.toBottomClick ();
    },
    clickTempCancel: function (){
      this.ChatTemp = false;
      this.CnslSave = false;  
      this.toBottomClick ();  
    },
    clickTempSave: function (){
      this.ChatTemp = false;
      this.CnslSave = false;
      this.toBottomClick ();
    },
    clickDone: function (){       
      this.ChatDone = true; 
      this.CnslSave = true;  
      this.toBottomClick ();
    },
    clickDoneCancel: function (){
      this.ChatDone = false; 
      this.CnslSave = false; 
      this.toBottomClick ();   
    },
    clickDoneSave: function (){
      this.ChatDone = false; 
      this.CnslSave = false; 
      this.toBottomClick ();
    },
    clickTransfer: function (){
      this.ChatTransfer = true;
      this.CnslSave = true;
      this.toBottomClick ();
    },
    clickTransferCancel: function (){
      this.ChatTransfer = false;
      this.CnslSave = false;
      this.toBottomClick ();
    },
    clickTransferSave: function (){
      this.ChatTransfer = false;
      this.CnslSave = false;
      this.toBottomClick ();
    },

    toBottomClick() {
      const element = document.getElementById("scrollMe");       
      setTimeout(() => { 
        element.scrollIntoView({behavior: "smooth"});
        this.ToBottomButton = false;  
      }, 500);
    },     
    onScroll (e) {
        var scrollTop = e.target.scrollTop;
        var innerHeight = e.target.clientHeight;
        var scrollHeight = e.target.scrollHeight;
        if(scrollTop + innerHeight >= scrollHeight -70){
            this.ToBottomButton = false;
        }else{
            this.ToBottomButton = true;
        }
    },
    showToBottom (){            
      var container = document.querySelector("#scroll-target");
      var innerHeight = container.clientHeight;
      var scrollHeight = container.scrollHeight;
      if(innerHeight < scrollHeight){
          this.ToBottomButton = true;
      }else{
          this.ToBottomButton = false;
      }
    },
    
    rowClick: function (item, row) { 
      if(this.selectedId===item.index){
        row.select(false);
        this.selectedId = item.subject;
        this.scriptDetail = false;
      }else{
        row.select(true);
        this.selectedId = item.index;
        this.scriptDetail = true;
      }
    },
    rowHistoryClick: function (item, row) { 
      if(this.selectedId===item.index){
        row.select(false);
        row.expand(false);
        this.selectedId=item.name;
      }else{
        row.select(true);
        this.selectedId=item.index;
        row.expand(true);
      }
    },
  },

  computed: {
    rowNumScript() {
      return this.ScriptData.map(
          (ScriptData, index) => ({
          ...ScriptData,
          index: index + 1
      }))
    },
    content() {
      const selectedRow = this.ScriptList[0];
      return selectedRow ? selectedRow.contents : "선택 스크립트가 없습니다.";
    },
    subject() {
      const selectedRow = this.ScriptList[0];
      return selectedRow ? selectedRow.subject : "선택 스크립트가 없습니다.";
    },
    rowNumLink() {
      return this.LinkData.map(
          (LinkData, index) => ({
          ...LinkData,
          index: index + 1
      }))
    },
    rowNumHistory() {
      return this.HistoryData.map(
          (HistoryData, index) => ({
          ...HistoryData,
          index: index + 1
      }))
    },

    showDialog(type){
      this[`dialog${type}`] = true
    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },
  },

  created() {
  },

  mounted() {
    this.toBottomClick ();
  },

  watch: {
    // count: function() {
    //   this.$nextTick(function() {
    //     var container = this.$refs.msgContainer;
    //     container.scrollTop = container.scrollHeight + 120;
    //   });
    // }
  }
};
</script>


<style></style>